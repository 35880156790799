<template>
    <v-container class="ma-0 pa-0 mx-auto">
        <vue-particles
            v-if="!$vuetify.breakpoint.mobile"
            class="my-auto"
            color="#ffffff"
            linesColor="#ffffff"
            shapeType="circle"
            style="position: fixed; height: 100%; width: 100%" />

        <v-card class="bg-primary lighten-2 mx-auto overflow-y-auto" style="height: calc(100vh - 48px)">
            <v-alert class="warning">This demo account only partially works.  Not all features and buttons will respond.</v-alert>

            <v-subheader dark>Tools To Demo</v-subheader>

            <v-row dense>
                <v-col v-for="(portal, pIndex) in portals" :cols="portal.large === true ? 12 : 6" :md="portal.large === true ? 6 : 4" :key="pIndex">
                    <v-card class="bg-primary mx-2 my-1" dark>
                        <div class="text-center pt-5">
                            <v-icon size="50">{{ portal.icon }}</v-icon>
                        </div>
                        <v-card-title>{{ portal.title }}</v-card-title>
                        <v-card-text>{{ portal.text }}</v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <BT-Btn 
                                inline 
                                label="Try It Out" 
                                text
                                @click="navToDemo(portal)" />
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
   </v-container>
</template>

<script>

export default {
  name: "Demo-Portal",
    destroyed() {
        this.$BlitzIt.style.undoTemporaryColor(this.$vuetify);
    },
  computed: {
    portals() {
        return [
            {
                bladeName: 'customer-dashboard',
                icon: 'mdi-account',
                large: true,
                subscription: 'CUSTFREE',
                text: 'Check out the customer online ordering experience',
                title: 'Customer Dashboard'
            },
            // {
            //     bladeName: 'dispatch-training',
            //     icon: 'mdi-clipboard-arrow-right',
            //     text: '',
            //     title: 'Dispatch',
            // },
            {
                bladeName: 'driver-hub',
                icon: 'mdi-clipboard-arrow-up',
                text: 'See what it\'s like when a customer drops by to pickup their order',
                title: 'Pickups',
                query: { mode: 'pickup' }
            },
            { 
                bladeName: 'driver-hub', 
                icon: 'mdi-truck-delivery',
                text: 'Test out the tool that drivers will use to do deliveries and records signatures.',
                title: 'Driving',
                query: { mode: 'journey' }
            },
            {
                bladeName: 'journey-optimization-hub',
                icon: 'mdi-truck-fast',
                text: 'Managing journeys can be a lot easier with the right tool like this one.',
                title: 'Journeys',
            },
            { 
                bladeName: 'packing-hub',
                icon: 'mdi-package-variant',
                text: 'Putting products into packaging to fill an order.',
                title: 'Packing'
            },
            { 
                bladeName: 'picking-hub',
                icon: 'mdi-clipboard',
                text: 'Picking products from what\'s in stock to fill an order or load a truck.',
                title: 'Picking',
            },
            { 
                bladeName: 'production-hub',
                icon: 'mdi-factory',
                text: 'Manufacturing batches of product to put into stock.',
                title: 'Production'
            },
            {
                bladeName: 'sales-hub',
                icon: 'mdi-receipt',
                text: 'Create and manage customers\' orders.',
                title: 'Sales'
            },
            {
                bladeName: 'order-sheets-hub',
                icon: 'mdi-list-box',
                text: 'Use a sheet of expected regular orders to follow up on and chase down sales.',
                title: 'Sheets',
            },
            // {
            //     bladeName: 'instant-sale-training',
            //     icon: 'mdi-cash-register',
            //     text: 'For spontaneous sales when a customer arrives unexpectedly.',
            //     title: 'Instant',
            // },
            {
                bladeName: 'stock-checker-hub',
                icon: 'mdi-file-chart',
                text: 'See what stock is currently available in the warehouse.',
                title: 'Levels',
            },
            {
                bladeName: 'stock-balancer-hub',
                icon: 'mdi-scale-balance',
                text: 'Balance stock between locations and create production orders.',
                title: 'Balancer',
            }
        ]
    }
  },
  mounted() {
    this.$BlitzIt.style.setTemporaryColor(this.$vuetify, this.$BlitzIt.style.root.cosmetics.dark['bg-primary']);
  },
  methods: {
    navToDemo(portal) {
        var n = { name: portal.bladeName, params: portal.params, query: { ...portal.query, isTraining: true }};
        this.$BlitzIt.auth.startTraining(portal.subscription || 'SUPP');
        this.$router.push(n);
    }
  }
};

</script>